<template>
  <div class="page1">
    <div class="container">
        <div class="title" v-if="ticketObj.title" style="margin-bottom: 10px">{{ticketObj.title}}</div>
        <img class="code" v-if="ticketObj.debtPayUrl" src="../imgs/code.png" alt="">
        <div class="tip" v-if="ticketObj.scanCodeInfo">{{ticketObj.scanCodeInfo}}</div>
        <div class="item" v-if="ticketObj.plateNumber"><span class="label">车牌号码：</span><span class="info">京A12345</span></div>
        <div class="item" v-if="ticketObj.plateColor"><span class="label">车牌颜色：</span><span class="infos">蓝色</span></div>
        <div class="item" v-if="ticketObj.parkName"><span class="label">停放路段：</span><span class="infos">这里显示停放路段名称</span></div>
        <div class="item" v-if="ticketObj.count"><span class="label">缴费单数：</span><span class="infos">**</span></div>
        <div class="item" v-if="ticketObj.shouldMoney"><span class="label">应付金额：</span><span class="infos">这里显示应付金额</span></div>
        <div class="item" v-if="ticketObj.money"><span class="label">实付金额：</span><span class="infos">这里显示实付金额</span></div>
        <div class="item" v-if="ticketObj.payTime"><span class="label">支付时间：</span><span class="infos">xxxx-xx-xx xx：xx：xx</span></div>
        <div class="item" v-if="ticketObj.payType"><span class="label">支付方式：</span><span class="infos">这里显示支付方式</span></div>
        <div class="item" v-if="ticketObj.operationName"><span class="label">运营单位：</span><span class="infos">智慧互通科技股份有限公司</span></div>
        <div class="item" v-if="ticketObj.pdaManagerCode"><span class="label">收费员编号：</span><span class="infos">这里显示收费员编号</span></div>
        <div class="line"  v-if="ticketObj.remark || ticketObj.printTime"></div>
        <!-- <div class="pay">未缴欠费：**.**元</div>
        <img class="code" src="../imgs/code.png" alt="">
        <div class="tip">请使用微信/支付宝扫一扫，完成自助缴费。</div> -->
        <div class="item" v-if="ticketObj.remark"><span class="label">温馨提示：</span><span class="infos">{{ticketObj.remark}}</span></div>
        <div class="item last_item" v-if="ticketObj.printTime"><span class="label time">打印时间: </span><span class="infos">xxxx-xx-xx xx：xx：xx</span></div>

    </div>
  </div>
</template>
<script>
export default {
  name: "DebtTicket",
  data() {
    return {
    };
  },
  props: ['ticketObj'],
  methods: {},
  components: {},
  created() {
    // this.getResource();
  },
  mounted() {
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.container 
  margin 0 auto
  width 400px
  min-height 500px
  border 1px solid #C0CCDA 
  padding 20px 0
  display flex
  flex-direction column
  align-items center
.code 
  width 200px
  height 200px
  margin-bottom 10px
.tip
  margin-bottom 20px
.item 
  width 100%
  margin-bottom 20px
  color rgb(51, 51, 51)
  font-weight 650
  font-style normal
  font-size 16px
  display flex
  align-items center
  padding 0 20px
  box-sizing border-box
.info 
  font-weight 650
  font-style normal
  font-size 24px
.infos
  font-weight 400
  font-style normal
  font-size 16px
  color rgb(51, 51, 51)
.label 
  display inline-block
  width 90px
  flex-shrink 0
.line 
  width 100%
  border-bottom 1px dashed #C0CCDA 
  margin-bottom 20px
.pay
  font-weight 650
  font-style normal
  font-size 24px
  margin-bottom 10px
.item:last-child
   margin-bottom 0 
.last_item:last-child
  margin-bottom 0 
  margin-top 140px
</style>
