var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "container" }, [
      _vm.ticketObj.title
        ? _c(
            "div",
            { staticClass: "title", staticStyle: { "margin-bottom": "10px" } },
            [_vm._v(_vm._s(_vm.ticketObj.title))]
          )
        : _vm._e(),
      _vm.ticketObj.innerPayUrl
        ? _c("img", {
            staticClass: "code",
            attrs: { src: require("../imgs/code.png"), alt: "" },
          })
        : _vm._e(),
      _vm.ticketObj.scanCodeInfo
        ? _c("div", { staticClass: "tip" }, [
            _vm._v(_vm._s(_vm.ticketObj.scanCodeInfo)),
          ])
        : _vm._e(),
      _vm.ticketObj.plateNumber
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("车牌号码：")]),
            _c("span", { staticClass: "info" }, [_vm._v("京A12345")]),
          ])
        : _vm._e(),
      _vm.ticketObj.plateColor
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("车牌颜色：")]),
            _c("span", { staticClass: "infos" }, [_vm._v("蓝色")]),
          ])
        : _vm._e(),
      _vm.ticketObj.parkName
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("停放路段：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("这里显示停放路段名称"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.berthCode
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("停放泊位：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("这里显示停车泊位编号"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.entryTime
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("入场时间：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("xxxx-xx-xx xx：xx：xx"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.operationName
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("运营单位：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("智慧互通科技股份有限公司"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.pdaManagerCode
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("收费员编号：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("这里显示收费员编号"),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "pay" }, [_vm._v("未缴欠费：**.**元")]),
      _vm.ticketObj.debtPayUrl
        ? _c("img", {
            staticClass: "code",
            attrs: { src: require("../imgs/code.png"), alt: "" },
          })
        : _vm._e(),
      _vm.ticketObj.scanCodeInfo
        ? _c("div", { staticClass: "tip" }, [
            _vm._v(_vm._s(_vm.ticketObj.scanCodeInfo)),
          ])
        : _vm._e(),
      _vm.ticketObj.remark
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("温馨提示：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v(_vm._s(_vm.ticketObj.remark)),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.printTime
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label time" }, [_vm._v("打印时间: ")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("xxxx-xx-xx xx：xx：xx"),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }